import React from 'react';
import styled, { css } from 'styled-components';
import { useRouter } from 'next/router';

import { MenuItemType } from '@tager/web-modules';

import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import Link from '@/components/Link';

type Props = {
  menuItemList: Array<MenuItemType>;
  setShowMobileMenu: (value: boolean) => void;
  onCookiesClick?: () => void;
};

function HeaderNav({ menuItemList, setShowMobileMenu, onCookiesClick }: Props) {
  const router = useRouter();

  const handleMenuItemClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    link: string | null | undefined
  ) => {
    if (link === '#') {
      e.preventDefault();
      if (onCookiesClick) {
        onCookiesClick();
      }
      return;
    }
  };

  return (
    <Nav>
      <NavList>
        {menuItemList.map((menuItem, index) => {
          return (
            <NavItem
              key={menuItem.id}
              isActive={router.asPath === menuItem.link}
              onClick={() =>
                menuItem.link === '#'
                  ? setShowMobileMenu(true)
                  : setShowMobileMenu(false)
              }
            >
              <ItemLink
                to={menuItem.link ?? '#'}
                dangerouslySetInnerHTML={{ __html: menuItem.label }}
                onClick={(e) => {
                  handleMenuItemClick(e, menuItem.link);
                }}
              />
            </NavItem>
          );
        })}
      </NavList>
    </Nav>
  );
}

const Nav = styled.div``;

const NavList = styled.ul`
  display: flex;
  align-items: center;
  margin: 10px 0;

  ${media.tablet(css`
    flex-wrap: wrap;
    margin: -10px;
  `)}
`;

const NavItem = styled.li<{ isActive: boolean }>`
  position: relative;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: ${colors.white40};
  transition: all 0.3s linear;

  ${media.laptop(css`
    font-size: 12px;
    line-height: 18px;
  `)}

  ${media.tablet(css`
    flex: 1 1 50%;
    max-width: 50%;
    padding: 10px;
  `)}
  
  ${media.tabletOnly(css`
    font-size: 16px;
    line-height: 20px;
  `)}
  
  ${media.mobile(css`
    font-size: 12px;
    line-height: 20px;
  `)}

  &:hover {
    color: ${colors.gray50};
  }

  ${(props) =>
    props.isActive
      ? css`
          transition: all 0s linear;
          color: ${colors.gray50};
          pointer-events: none;
        `
      : ''}
`;

const ItemLink = styled(Link)`
  padding: 0 8px;
  display: flex;
  align-items: flex-start;

  ${media.tablet(css`
    padding: 0;
    cursor: default;
  `)}

  i {
    font-style: normal;
    margin-left: 2px;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: ${colors.orange};
    pointer-events: none;

    ${media.mobile(css`
      font-size: 8px;
      line-height: 10px;
    `)}
  }
`;

export default HeaderNav;
