import React, { useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { StringField } from '@/typings/model';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import { ReactComponent as ArrowDown } from '@/assets/svg/arrow-down-small.svg';

interface Props {
  title: StringField;
  children: React.ReactNode;
}

function AccordionMenu({ title, children }: Props) {
  const [isActive, setActive] = useState(false);
  const [currentHeight, setHeight] = useState<number>(0);
  const ref = useRef<HTMLDivElement>(null);

  function onClick() {
    if (!isActive && ref.current) {
      const maxHeight = ref.current.scrollHeight ?? 0;
      setActive(true);
      setHeight(maxHeight);
    } else {
      setActive(false);
      setHeight(0);
    }
  }

  return (
    <Accord isActive={isActive}>
      <Top onClick={onClick}>
        <Title>{title}</Title>
        <Icon>
          <ArrowDown />
        </Icon>
      </Top>
      <Content ref={ref} currentHeight={currentHeight}>
        <ContentInner>{children}</ContentInner>
      </Content>
    </Accord>
  );
}

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 96px;

  ${media.mobile(css`
    padding-right: 45%;
  `)}
`;

const Content = styled.div<{ currentHeight: number }>`
  max-height: ${(props) => props.currentHeight}px;
  overflow: hidden;
  transition: all 0.2s linear;
`;

const ContentInner = styled.div`
  padding-top: 23px;

  ${media.mobile(css`
    padding-top: 20px;
  `)}
`;

const Title = styled.span`
  font-weight: bold;
  font-size: 20px;
  line-height: 26px;
  color: ${colors.white};
  margin-right: 20px;

  ${media.mobile(css`
    font-size: 16px;
    line-height: 20px;
  `)}
`;

const Icon = styled.div`
  color: ${colors.white};
  transition: all 0.3s linear;

  ${media.tabletSmall(css`
    svg {
      width: 19px;
      height: 23px;
    }
  `)}
`;

const Accord = styled.div<{ isActive: boolean }>`
  ${(props) =>
    props.isActive
      ? css`
          ${Icon} {
            svg {
              transform: rotate(-180deg);
            }
          }
        `
      : ''};
`;

export default AccordionMenu;
