import React from 'react';
import styled, { css } from 'styled-components';

import { Nullable } from '@tager/web-core';

import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

type Props = {
  children?: React.ReactNode;
  title: Nullable<string>;
};

function FooterBlock({ title, children }: Props) {
  return (
    <Inner>
      <Title>{title}</Title>
      <Content>{children}</Content>
    </Inner>
  );
}

const Inner = styled.div`
  margin-bottom: 48px;
  ${media.laptop(css`
    margin-bottom: 32px;
  `)}

  &:first-child {
    margin-bottom: 52px;

    ${media.laptop(css`
      margin-bottom: 32px;
    `)}

    ${media.tabletSmall(css`
      margin-bottom: 46px;
    `)}
    
    ${media.mobile(css`
      margin-bottom: 55px;
    `)}
  }
`;

const Title = styled.h3`
  font-weight: bold;
  font-size: 36px;
  line-height: 48px;
  color: ${colors.white};

  ${media.laptop(css`
    font-size: 24px;
    line-height: 32px;
  `)}

  ${media.tablet(css`
    font-size: 20px;
    line-height: 26px;
  `)}
  
  ${media.mobile(css`
    font-size: 16px;
    line-height: 20px;
  `)}
`;

const Content = styled.div`
  margin-top: 20px;

  ${media.laptop(css`
    margin-top: 12px;
  `)}

  ${media.tabletSmall(css`
    margin-top: 19px;
  `)}
`;

export default FooterBlock;
