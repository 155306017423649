import React from 'react';
import styled, { css } from 'styled-components';

import { getSettingValueByKey } from '@tager/web-modules';

import { colors } from '@/constants/theme';
import FooterBlock from '@/components/Layout/components/Footer/components/FooterBlock';
import SocialBlock from '@/components/Layout/components/Footer/components/SocialBlock';
import AppBlock from '@/components/Layout/components/Footer/components/AppBlock';
import InformationBlock from '@/components/Layout/components/Footer/components/InformationBlock';
import DesignBy from '@/components/Layout/components/Footer/components/DesignBy';
import { useLayout } from '@/components/Layout/Layout.hooks';
import { useTypedSelector } from '@/store/store';
import { selectMenuItemListByAlias } from '@/store/reducers/tager/menus';
import { selectSettingItemList } from '@/store/reducers/tager/settings';
import FooterNavbarMobile from '@/components/Layout/components/Footer/components/FooterNavbarMobile';
import { StringField } from '@/typings/model';
import { media } from '@/utils/mixin';

import FooterBottomMenu from '../Footer/components/FooterBottomMenu';

type TMenuItem = { label: string; link: string };

interface Props {
  onCookiesClick?: () => void;
}

function FooterMobile({ onCookiesClick }: Props) {
  const { footerInformation, themePage } = useLayout();
  const footerMenu =
    useTypedSelector((state) => selectMenuItemListByAlias(state, 'footer')) ??
    [];

  const settingsList = useTypedSelector(selectSettingItemList);

  const copyright = getSettingValueByKey(settingsList, 'FOOTER_COPYRIGHT');
  const socialTitle = getSettingValueByKey(settingsList, 'FOOTER_SOCIAL_TITLE');
  const mobileTitle = getSettingValueByKey(settingsList, 'FOOTER_MOBILE_TITLE');
  const addressTitle = getSettingValueByKey(
    settingsList,
    'FOOTER_ADDRESS_TITLE'
  );
  const footerBottomMenu = getSettingValueByKey(
    settingsList,
    'FOOTER_BOTTOM_MENU'
  );

  return (
    <FooterContainer>
      <FooterInner>
        <FooterTop>
          <FooterTopInner>
            <FooterBlock title={socialTitle}>
              <SocialBlock />
            </FooterBlock>
            <FooterBlock title={mobileTitle}>
              <AppBlock />
            </FooterBlock>
            {footerInformation && (
              <FooterBlock title={addressTitle}>
                <InformationBlock address={footerInformation} />
              </FooterBlock>
            )}
          </FooterTopInner>
        </FooterTop>
        <FooterCenter>
          <FooterNavbarMobile navbarList={footerMenu} />
        </FooterCenter>
        <FooterBottom themePage={themePage}>
          <FooterBottomInner>
            <FooterBottomMenu
              onCookiesClick={onCookiesClick}
              themePage={themePage}
              menu={footerBottomMenu as unknown as TMenuItem[]}
            />
            <FooterCopyright>{copyright}</FooterCopyright>
          </FooterBottomInner>
        </FooterBottom>
        {/*<DesignByWrapper>*/}
        {/*  <DesignBy />*/}
        {/*</DesignByWrapper>*/}
      </FooterInner>
    </FooterContainer>
  );
}

const FooterContainer = styled.div``;

const FooterInner = styled.div`
  position: relative;
`;

const FooterTop = styled.div`
  margin-top: -30px;
  padding: 80px 20px;
  background: ${colors.black};
  position: relative;
  clip-path: polygon(0% 30px, 100% 0, 100% 100%, 0% 100%);
  z-index: 2;

  &:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${colors.white10};
  }
`;

const FooterTopInner = styled.div`
  position: relative;
`;

const FooterCenter = styled.div`
  position: relative;
  margin-top: -30px;
  padding: 86px 20px;
  background: ${colors.black};
  clip-path: polygon(0% 30px, 100% 0, 100% 100%, 0% 100%);
  z-index: 3;
`;

const FooterBottom = styled.div<{ themePage: StringField }>`
  margin-top: -1px;
  padding: 18px 95px 18px 20px;
  background: ${colors.black};
  position: relative;

  &::after {
    z-index: 3;
    width: calc(100% - 115px);
    display: block;
    position: absolute;
    top: 0;
    content: '';
    height: 1px;
    background: ${colors.gray100};
  }
`;

const FooterBottomInner = styled.div`
  max-width: calc(100% - 70px);
`;

const DesignByWrapper = styled.div`
  position: absolute;
  bottom: 0;
  right: 20px;

  ${media.mobileSmall(css`
    right: 10px;
  `)}
`;

const FooterCopyright = styled.div`
  color: ${colors.gray90};
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
`;

export default FooterMobile;
