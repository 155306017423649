export const colors = {
  white: '#FFFFFF',
  white6: 'rgba(255, 255, 255, 0.06)',
  white10: 'rgba(255, 255, 255, 0.1)',
  white40: 'rgba(255, 255, 255, 0.4)',
  white50: 'rgba(255, 255, 255, 0.45)',
  white80: 'rgba(255, 255, 255, 0.8)',
  black: '#020202',
  black32: 'rgba(2, 2, 2, 0.32)',
  black50: 'rgba(2, 2, 2, 0.5)',
  black80: 'rgba(2, 2, 2, 0.8)',
  black100: '#111111',
  black200: '#262626',
  black300: '#3b3b3b',
  black400: '#1F1F1F',
  black500: '#2D2D2D',
  black600: '#444444',
  black1000: '#000000',
  gray: '#888888',
  grayHover: '#EBE8E8',
  grayActive: '#C2C2C2',
  gray30: '#CCCCCC',
  gray40: '#FCFAFA',
  gray50: '#f1f1f1',
  gray60: '#c4c4c4',
  gray70: '#d9d9d9',
  gray80: '#EDEDED',
  gray90: '#B5B5B5',
  gray100: '#5A5A5A',
  gray110: '#D1D1D1',
  gray120: '#BDBBBC',
  grayDark: '#5f5f5f',
  grayLight: '#999999',
  yellow100: '#F4E557',
  yellow200: '#E4FF41',
  yellowHover100: '#F1DF36',
  yellowHover: '#DCFA2A',
  yellowActive: '#BBD618',
  orange: '#F9683A',
  orangeHover: '#EC6030',
  orangeActive: '#C64113',
  orange100: '#f85521',
  orange200: '#ffe0d6',
  red: 'red',
  red100: '#DB3C5A',
  red200: '#CD2544',
  redHover: '#CF304E',
  redActive: '#B91A38',
  blue: '#216cdc',
} as const;

/** Source: https://htmlacademy.ru/blog/useful/css/short-14 */
const fallbackFont = [
  'Raleway',
  'Inter',
  '-apple-system',
  "'BlickMacSystemFont'",
  "'Segoe UI'",
  "'Roboto'",
  "'Oxygen'",
  "'Ubuntu'",
  "'Cantarell'",
  "'Fira Sans'",
  "'Droid Sans'",
  "'Helvetica Neue'",
  'sans-serif',
].join(',');

export const fonts = {
  Montserrat: `'Montserrat', ${fallbackFont}`,
  Raleway: `'Raleway', ${fallbackFont}`,
  Inter: `'Inter', ${fallbackFont}`,
  NeueMachina: `'NeueMachina', ${fallbackFont}`,
};

/**
 * Reference - Screen Resolution Stats Worldwide:
 * https://gs.statcounter.com/screen-resolution-stats
 *
 * 16px - scrollbar width on Windows,
 * some browsers doesn't include scrollbar width when calculate media queries
 */
export const breakpoints = {
  /** iPhone 5/SE */
  mobileSmall: 320,
  /** iPhone 6/7/8/X */
  mobileMedium: 374,
  /** iPhone 6/7/8 Plus */
  mobileLarge: 520,
  /** iPad 1, 2, Mini and Air */
  tabletSmall: 768,

  tabletLarge: 1024,
  /** 1280 - 16 = 1264 -> 1260 - more beautiful number :) */
  laptop: 1260,
  /** 1536 - 16 = 1520 -> 1500 - more beautiful number :) */
  desktop: 1600,
  desktopLarge: 1920,
};
